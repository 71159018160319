<template>
  <div class="pages">
    <h1>Clube de Serviços</h1>
    <button class="btn btn-default new-page-btn" v-on:click="newItem">Adicionar conveniado</button>
    <div class="pages-wrapper">
      <div v-if="loading" class="loader">
        <p>Carregando itens...</p>
      </div>
      <div v-if="!loading && items.length === 0" style="text-align: center">
        <p>Nenhum item cadastrado.</p>
      </div>
      <div class="page" v-for="item in items" :key="item.id">
        <img :src="$mediaBaseUrl + item.logo" alt="" class="preview">
        {{ item.name.substring(0, 20) }}... -
        <small>Modificado em {{ item.updatedAt | moment("D/M/YYYY - H:m") }}</small>
        <router-link :to="'clube-de-servicos/editar/' + item.id" class="page-edit-btn"><font-awesome-icon icon="edit"/> Editar</router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios').default;

export default {
  name: 'list',
  data() {
    return {
      loading: true,
      items: []
    }
  },
  methods: {
    newItem() {
      this.$router.push('/clube-de-servicos/novo');
    }
  },
  created: function () {
    let self = this;
    axios.get(this.$apiUrl + 'membership-club/list')
      .then(response => {
        self.items = response.data.items;
        self.loading = false;
      })
      .catch(error => {
        this.$alert('', 'Erro', 'error');
        console.log(error.response.data.error);
        self.loading = false;
      });
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/global";
  @import "../../assets/scss/buttons";

  .pages {
    height: 100vh;
    overflow-y: scroll;
    padding: 20px 0;

    .loader {
      text-align: center;
    }

    .new-page-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 10px;
    }

    .pages-wrapper {
      margin-top: 50px;
      padding: 0 200px;
      text-align: left;

      .page {
        padding: 10px;
        margin-bottom: 10px;
        color: #fff;
        background-color: $primary;
        border-radius: 10px;
        position: relative;

        &.child {
          background-color: $alt;
          margin-top: 10px;
          margin-bottom: 0;
        }

        .preview {
          height: 20px;
          width: auto;
          vertical-align: middle;
          margin-right: 20px;
        }

        .page-edit-btn {
          background-color: #fff;
          padding: 2px 5px;
          margin-left: 10px;
          color: $primary;
          border-radius: 10px;
          text-decoration: none;
          position: absolute;
          top: 8px;
          right: 10px;
        }
      }
    }
  }
</style>
